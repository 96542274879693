import React from "react";
import {
  Button,
  EditButton,
  FieldProps,
  Pagination,
  RaRecord,
  ReferenceManyField,
  ShowBase,
  ShowProps,
  useRecordContext
} from "react-admin";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material";

import { ShowActions } from "../components/actions";
import { DetailsCard } from "../components/details";
import { ComputerIndicactor } from "../computers/components";
import { Link } from "react-router-dom";
import { ResultListGrid } from "../results/list";
import { QuestionMark } from "@mui/icons-material";

export const MachineDetail: React.FC<ShowProps<RaRecord>> = () => {
  return (
    <ShowBase>
      <Stack direction={"column"} spacing={2}>
        <Actions />
        <Details />
        <Computer />
        <Results />
      </Stack>
    </ShowBase>
  );
};

const Actions: React.FC = () => {
  const record = useRecordContext();

  return (
    <ShowActions title={`${record?.serialNumber}`}>
      <Stack direction={"row"}>
        <EditButton variant="contained" />
        <Button
          label="Report Issue"
          href={`/maintenance/create?source={"machineId": "${record?.id}"}`}
        >
          <QuestionMark />
        </Button>
      </Stack>
    </ShowActions>
  );
};

const Details: React.FC<FieldProps<RaRecord>> = () => {
  return (
    <DetailsCard
      title="Details"
      items={machine =>
        [
          {
            label: "",
            value: (
              <Box>
                <Chip
                  color={machine.status === "connected" ? "success" : "default"}
                  label={machine.status.toUpperCase()}
                />
              </Box>
            )
          },
          { label: "Manufacturer", value: machine?.model?.manufacturer },
          { label: "Model", value: machine?.model?.name },
          { label: "Serial Number", value: machine.serialNumber },

          { label: "Method", value: machine.connection?.method?.toUpperCase() },
          { label: "Path", value: machine.connection?.filePath },
          { label: "Host", value: machine.connection?.tcpIpHost },
          { label: "Port", value: machine.connection?.tcpIpPort },
          { label: "Port", value: machine.connection?.serialPort }
        ].filter(d => d.value)
      }
    />
  );
};

const Computer: React.FC<FieldProps<RaRecord>> = () => {
  const machine = useRecordContext();
  return (
    <Card>
      <CardHeader title="Host Computers" />
      <CardContent>
        <Grid direction={"row"} spacing={2} container>
          {machine?.computers?.map((computer: any) => (
            <Grid item xs={12} md={3} key={computer.id}>
              <Link to={`/computers/${computer.id}/show`}>
                <Stack
                  component={Paper}
                  variant="outlined"
                  padding={2}
                  spacing={1}
                >
                  <Stack spacing={1} direction={"row"}>
                    <ComputerIndicactor record={computer} />
                    <Chip
                      size="small"
                      variant="outlined"
                      label={computer?.osInfo?.hostname}
                    />
                  </Stack>
                  <Typography variant="body1">
                    {computer?.system?.model}
                  </Typography>
                </Stack>
              </Link>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const Results: React.FC<FieldProps<RaRecord>> = () => {
  const machine = useRecordContext();
  return (
    <Card>
      <CardHeader title="Results" />
      <ReferenceManyField
        perPage={10}
        reference="results"
        filter={{ machineId: machine }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        target="machineId"
        sort={{ field: "completedAt", order: "DESC" }}
      >
        <ResultListGrid />
      </ReferenceManyField>
    </Card>
  );
};
