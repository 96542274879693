// EquipmentErrorCreate.tsx
import {
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  ImageInput,
  ImageField,
  required
} from "react-admin";

const severityChoices = [
  { id: "low", name: "Low" },
  { id: "medium", name: "Medium" },
  { id: "high", name: "High" },
  { id: "critical", name: "Critical" }
];

const errorTypeChoices = [
  { id: "computer", name: "Computer" },
  { id: "software", name: "Software" },
  { id: "instrument", name: "Instrument" },
  { id: "calibration", name: "Calibration" }
];

export const MaintenanceCreate = () => (
  <Create title="Report Equipment Issue">
    <SimpleForm>
      <ReferenceInput reference="machines" source="machineId">
        <AutocompleteInput
          optionText={(v: any) =>
            `${v.serialNumber} - ${v.model?.manufacturer ?? ""} ${
              v.model?.name ?? ""
            }`
          }
          fullWidth
        />
      </ReferenceInput>

      <SelectInput
        source="severity"
        label="Severity"
        choices={severityChoices}
        validate={required()}
        fullWidth
      />

      <SelectInput
        source="errorType"
        label="Error Type"
        choices={errorTypeChoices}
        validate={required()}
        fullWidth
      />

      <TextInput
        source="description"
        label="Description"
        multiline
        minRows={5}
        validate={required()}
        fullWidth
      />

      <ImageInput
        source="photos"
        label="Upload Images"
        accept="image/*"
        multiple
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
