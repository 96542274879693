import React from "react";
import {
  ShowBase,
  useRecordContext,
  EditButton,
  useGetList
} from "react-admin";
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  Chip,
  Grid,
  Typography,
  Paper
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ShowActions } from "../components/actions";

export const FacilityShow: React.FC = () => {
  return (
    <ShowBase>
      <Stack direction="column" spacing={2}>
        <Actions />
        <Instruments />
        <Computers />
        <Routers />
      </Stack>
    </ShowBase>
  );
};

const Actions: React.FC = () => {
  const record = useRecordContext();
  return (
    <ShowActions title={record?.name}>
      <EditButton variant="contained" />
    </ShowActions>
  );
};

const Instruments: React.FC = () => {
  const record = useRecordContext();
  const { data: instruments = [] } = useGetList("machines", {
    filter: { facilityId: [record.id] }
  });

  return (
    <Card>
      <CardHeader title="Instruments" />
      <CardContent>
        <Stack direction="row" flexWrap="wrap" gap={1}>
          {instruments.map((machine: any) => (
            <Chip
              key={machine.id}
              icon={
                <Circle
                  fontSize="small"
                  color={machine.status === "connected" ? "success" : "warning"}
                />
              }
              label={`${machine.model?.manufacturer} ${machine.model?.name}`}
              variant="outlined"
              size="small"
              component={Link}
              to={`/machines/${machine.id}/show`}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

const Computers: React.FC = () => {
  const record = useRecordContext();

  const { data: computers = [] } = useGetList("computers", {
    filter: { facilityId: record.id }
  });

  return (
    <Card>
      <CardHeader title="Computers" />
      <CardContent>
        <Grid container spacing={2}>
          {computers.map((computer: any) => (
            <Grid item xs={12} md={4} key={computer.id}>
              <Link to={`/computers/${computer.id}/show`}>
                <Stack
                  component={Paper}
                  variant="outlined"
                  padding={2}
                  spacing={1}
                >
                  <Typography variant="caption">
                    {computer?.osInfo?.hostname}
                  </Typography>
                  <Typography variant="body1">
                    {computer?.system?.model}
                  </Typography>
                </Stack>
              </Link>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const Routers: React.FC = () => {
  const record = useRecordContext();
  const { data: computers = [] } = useGetList("computers", {
    filter: { facilityId: [record.id] }
  });

  const networks = computers
    .flatMap(computer => computer.networkInterfaces)
    .filter(d => d.ip4 && d.ip4 !== "127.0.0.1");

  return (
    <Card>
      <CardHeader title="Routers" />
      <CardContent>
        <Grid container spacing={2}>
          {networks.map((router: any) => (
            <Grid item xs={12} md={3} key={router.id}>
              <Link to={`/router`}>
                <Stack
                  component={Paper}
                  variant="outlined"
                  padding={2}
                  spacing={1}
                >
                  <Typography variant="body2">{router.iface}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {router.ip4}
                  </Typography>
                </Stack>
              </Link>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
