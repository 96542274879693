import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig
} from "axios";

import * as config from "../config";
import swConfig from "../swConfig";
import pkg from "../../package.json";

export const client = withVersion(
  withAuth(
    axios.create({
      baseURL: config.backendUrl,
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0"
      }
    })
  )
);

export function withAuth(client: AxiosInstance): AxiosInstance {
  client.interceptors.request.use((request: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem("token");

    const headers = request.headers;

    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return {
      ...request,

      headers
    };
  });

  return client;
}

export function withVersion(client: AxiosInstance) {
  let alerted = false;

  client.interceptors.request.use(request => {
    const headers = request.headers;
    headers.set("Version", pkg.version);
    return {
      ...request,
      headers
    };
  });

  client.interceptors.response.use(
    undefined,
    (error: AxiosError<{ type: string; message: string }>) => {
      if (error?.response?.data?.type === "VersionError" && !alerted) {
        const { message } = error?.response?.data;
        return Promise.resolve(alert(message))
          .then(() => (alerted = true))
          .then(() => navigator.serviceWorker.getRegistration())
          .then(swRegistration => swConfig.onUpdate(swRegistration))
          .catch(() => window.location.reload());
      }

      return Promise.reject(error);
    }
  );

  return client;
}
