import {
  Pagination as RaPagination,
  TextInput,
  ListBase,
  FilterForm,
  FilterButton,
  CreateButton
} from "react-admin";
import { MobileListGrid } from "../components/mobile-list-grid";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { Circle } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";

export const FacilityList = () => (
  <ListBase
    perPage={50}
    sort={{ field: "updatedAt", order: "DESC" }}
    filterDefaultValues={{ suspended: false }}
  >
    <Toolbar />
    <MobileListGrid
      link={(record: any): string => `/facilities/${record.id}/show`}
      content={(record: any) => (
        <Stack spacing={1}>
          <Typography variant="body1">{record.name}</Typography>
          <Grid2 direction={"row"} spacing={1} container>
            {record.machines.map((machine: any) => (
              <Grid2 key={machine.id}>
                <Chip
                  size="small"
                  icon={
                    <Circle
                      color={
                        machine.status === "connected" ? "success" : "warning"
                      }
                    />
                  }
                  variant="outlined"
                  label={`${machine.model.manufacturer} ${machine.model.name}`}
                />
              </Grid2>
            ))}
          </Grid2>
        </Stack>
      )}
      metadata={(record: any) => (
        <Typography variant="caption" color={"primary"}>
          {record.organisation?.name}
        </Typography>
      )}
      right={null}
    />
    <Pagination />
  </ListBase>
);

const Pagination = () => (
  <RaPagination rowsPerPageOptions={[10, 25, 50, 100]} perPage={50} />
);

const Toolbar = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
      <FilterForm filters={userFilters} />
      <Box display={"flex"} flexDirection={"row"}>
        <FilterButton filters={userFilters} />
        <CreateButton />
      </Box>
    </Stack>
  );
};

const userFilters = [
  <TextInput label="Name" source="search.name" variant="outlined" />
];
