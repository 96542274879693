import { MultiDashboard } from "@medx/dashboards";

import { backendUrl } from "./config";
import { withAuth, withVersion } from "./providers/client";
import axios from "axios";

export const Dashboard = () => {
  return (
    <MultiDashboard
      client={withVersion(
        withAuth(axios.create({ baseURL: backendUrl + "/portal/reports" }))
      )}
    />
  );
};
