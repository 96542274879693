import { Admin, CustomRoutes, Resource } from "react-admin";

import { theme } from "./theme";
import { createRestProvider } from "./providers/rest";
import { client } from "./providers/client";
import { authProvider } from "./providers/auth";
import { LoginPage } from "./auth/login";
import { users } from "./users";
import { i18nProvider } from "./i18n";
import { BrowserRouter, Route } from "react-router-dom";
import { ResetPassword } from "./auth/reset";
import { ForgotPassword } from "./auth/forgot";
import { MyLayout } from "./layout";
import {
  adminRole,
  facilityRole,
  organisationRole,
  superAdminRole,
  vendorRole
} from "./roles";
import { machineManufacturers, machineModels, machines } from "./machines";
import { computers } from "./computers";
import { organisations } from "./organisation";
import { facilities } from "./facilities";
import { results } from "./results";
import { messages } from "./messages";
import { Dashboard } from "./dashboard";
import { maintenance } from "./maintenance";
import { RouterDashboard } from "./facilities/router";

export const App = () => (
  <BrowserRouter>
    <Admin
      authProvider={authProvider}
      dataProvider={createRestProvider(client)}
      theme={theme}
      loginPage={LoginPage}
      dashboard={Dashboard}
      i18nProvider={i18nProvider}
      layout={MyLayout}
    >
      {user => (
        <>
          {getResources(user).map(resource => (
            <Resource key={resource.name} {...resource} />
          ))}
          <CustomRoutes noLayout>
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/reset/:token" element={<ResetPassword />} />
          </CustomRoutes>
          <CustomRoutes>
            <Route path="/router" element={<RouterDashboard />} />
          </CustomRoutes>
        </>
      )}
    </Admin>
  </BrowserRouter>
);

function getResources(user: any) {
  if ([superAdminRole, adminRole].includes(user.role)) {
    return [
      facilities,
      machines,
      machineModels,
      machineManufacturers,
      computers,
      results,
      organisations,
      users,
      maintenance,
      messages
    ];
  }

  if ([organisationRole].includes(user.role)) {
    return [
      facilities,
      machines,
      machineModels,
      machineManufacturers,
      computers,
      { ...organisations, list: undefined },
      results,
      users,
      maintenance
    ];
  }

  if ([facilityRole].includes(user.role)) {
    return [
      facilities,
      machines,
      machineModels,
      machineManufacturers,
      computers,
      results,
      users,
      maintenance
    ];
  }

  if ([vendorRole].includes(user.role)) {
    return [
      facilities,
      machines,
      machineModels,
      machineManufacturers,
      computers,
      results,
      organisations,
      users,
      maintenance,
      messages
    ];
  }

  return [];
}
