import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Stack,
  List,
  ListItem,
  ListItemText,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";

// Initial dummy data
const initialHistory = [
  { date: "2025-03-31", amount: 5 },
  { date: "2025-03-28", amount: 10 },
  { date: "2025-03-20", amount: 3 }
];

const initialWhitelist = [
  "LabXpert Equipment",
  "LabXpertDS",
  "AnyDesk",
  "Team Viewer",
  "CPHL LIMS"
];

const usageData = [
  { day: "Mon", used: 5 },
  { day: "Tue", used: 8 },
  { day: "Wed", used: 6 },
  { day: "Thu", used: 10 },
  { day: "Fri", used: 7 },
  { day: "Sat", used: 4 },
  { day: "Sun", used: 9 }
];

export const RouterDashboard: React.FC = () => {
  const [totalGB, setTotalGB] = useState(50);
  const usedGB = 35;
  const [history, setHistory] = useState(initialHistory);
  const [whitelist, setWhitelist] = useState(initialWhitelist);
  const [newService, setNewService] = useState("");

  const handleTopUp = (gb: number) => {
    setTotalGB(prev => prev + gb);
    setHistory(prev => [
      { date: new Date().toISOString(), amount: gb },
      ...prev
    ]);
  };

  const handleAddWhitelist = () => {
    if (!newService.trim()) return;
    setWhitelist(prev => [...prev, newService.trim()]);
    setNewService("");
  };

  const handleRemoveWhitelist = (serviceToRemove: string) => {
    setWhitelist(prev => prev.filter(service => service !== serviceToRemove));
  };

  return (
    <Stack direction={"column"} spacing={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Typography variant="h6">Router #001</Typography>
        <TopUpDialogButton onTopUp={handleTopUp} />
      </Stack>

      <Card>
        <CardHeader title="Data Usage" />
        <CardContent>
          <Stack direction="row" spacing={4} mb={2}>
            <Box>
              <Typography variant="h6">Used</Typography>
              <Typography variant="h4">{usedGB} GB</Typography>
            </Box>
            <Box>
              <Typography variant="h6">Total</Typography>
              <Typography variant="h4">{totalGB} GB</Typography>
            </Box>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" gutterBottom>
            Weekly Usage
          </Typography>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={usageData}>
              <XAxis dataKey="day" />
              <YAxis unit=" GB" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="used"
                stroke="#1976d2"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Top-up History" />
        <CardContent>
          <List dense>
            {history.map((entry, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`${entry.amount} GB`}
                  secondary={new Date(entry.date).toLocaleDateString()}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Whitelisted Services" />
        <CardContent>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1} flexWrap="wrap">
              {whitelist.map((service, index) => (
                <Chip
                  key={index}
                  label={service}
                  variant="outlined"
                  onDelete={() => handleRemoveWhitelist(service)}
                />
              ))}
            </Stack>
            <Stack direction="row" spacing={1}>
              <TextField
                label="Add service"
                value={newService}
                onChange={e => setNewService(e.target.value)}
                size="small"
              />
              <Button
                variant="outlined"
                onClick={handleAddWhitelist}
                disabled={!newService.trim()}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

type Props = {
  onTopUp: (gb: number) => void;
};

const TopUpDialogButton: React.FC<Props> = ({ onTopUp }) => {
  const [open, setOpen] = useState(false);
  const [gb, setGb] = useState("");

  const handleClose = () => setOpen(false);

  const handleAccept = () => {
    const parsed = parseFloat(gb);
    if (!parsed || parsed <= 0) return;
    onTopUp(parsed);
    setGb("");
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Top Up
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Top Up Data</DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={1}>
            <TextField
              label="Enter GB to top up"
              type="number"
              value={gb}
              onChange={e => setGb(e.target.value)}
              fullWidth
              autoFocus
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleAccept}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
