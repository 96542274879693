import { Settings } from "@mui/icons-material";
import { ResourceProps } from "react-admin";

import { MaintenanceCreate } from "./create-edit";

export const maintenance: ResourceProps = {
  name: "maintenance",
  icon: Settings,
  create: MaintenanceCreate
};
